import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Gallery from "react-photo-gallery";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import {
  Connection,
  ImageFileNode,
  MarkdownFileNode,
  QueryFragment,
} from "../models";
import MarkdownContent from "../components/MarkdownContent";

const GalleryPage: React.FC<PageProps> = () => {
  const queryResult = useStaticQuery<GalleryPageQueryResult>(graphql`
    query GalleryPageQuery {
      allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
        nodes {
          childImageSharp {
            fixed {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      file(sourceInstanceName: { eq: "pages" }, name: { eq: "gallery" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);
  const content = queryResult.file.childMarkdownRemark;
  const photos = queryResult.allFile.nodes.map((t) => t.childImageSharp.fixed!);
  return (
    <Layout>
      <SEO title="Gallery" />
      <PageHeader title="Gallery" />
      {content === null ? null : <MarkdownContent html={content.html} />}
      <Gallery photos={photos} />
    </Layout>
  );
};

GalleryPage.displayName = "GalleryPage";

export default GalleryPage;

type GalleryPageQueryResult = QueryFragment<
  "allFile",
  Connection<ImageFileNode>
> &
  QueryFragment<"file", MarkdownFileNode>;
